import { Component, input, output } from '@angular/core';
import { ScrollPaginationDirective } from './scroll-pagination.directive';

@Component({
  selector: 'mlk-scroll-pagination',
  imports: [ScrollPaginationDirective],
  styles: `
  .loading-more{
    text-align:center;
  }
  `,
  template: `
    <ng-content></ng-content>
    @if (canNext()) {
      <div
        (nextPage)="nextPage.emit($event)"
        mlkScrollPagination
        [totalItem]="totalItem()"
        [isLoading]="isLoading()"
        class="loading-more">
        More
      </div>
    }
  `,
  standalone: true,
})
export class ScrollPaginationComponent {
  readonly canNext = input.required<boolean>();

  readonly totalItem = input.required<number>();

  readonly isLoading = input.required<boolean>();

  readonly nextPage = output<number>();
}
