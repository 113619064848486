<div class="form-group mb-3">
  <label class="form-label fw-bold" for="description">{{ label }}</label>
  <textarea
    class="form-control"
    cols="50"
    [disabled]="disabled"
    [minLength]="min"
    [maxLength]="max"
    [placeholder]="placeholder"
    [name]="name"
    [value]="value"
    [id]="id"
    rows="6"></textarea>

  <ng-content select=".prevention-message"></ng-content>
</div>
