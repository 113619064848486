import {
  Directive,
  ViewContainerRef,
  OnChanges,
  TemplateRef,
  inject,
  DestroyRef,
  input,
} from '@angular/core';

type LoopContext = { $implicit: number; index: number };

@Directive({
  selector: '[mlkLoopFor]',
  standalone: true,
})
export class LoopDirective implements OnChanges {
  private readonly destroyRef = inject(DestroyRef);

  readonly mlkLoopFor = input.required<number>();

  readonly mlkLoopForReverse = input(false);

  constructor(
    private container: ViewContainerRef,
    private template: TemplateRef<unknown>
  ) {
    this.destroyRef.onDestroy(() => this.container.clear());
  }

  public ngOnChanges(): void {
    this.container.clear();
    if (this.mlkLoopForReverse()) {
      this.reverseLoop();
    } else {
      this.loop();
    }
  }

  loop(): void {
    for (let index = 0; index < this.mlkLoopFor(); index++) {
      this.container.createEmbeddedView(this.template, {
        $implicit: index,
        index,
      });
    }
  }

  reverseLoop(): void {
    for (let index = this.mlkLoopFor() - 1; index >= 0; index--) {
      this.container.createEmbeddedView(this.template, {
        $implicit: index,
        index,
      });
    }
  }

  static ngTemplateContextGuard(
    directive: LoopDirective,
    context: unknown
  ): context is LoopContext {
    return true;
  }
}
