import { Pipe, PipeTransform } from '@angular/core';

type NotifTypeClass = {
  class: string;
  title: string;
};

@Pipe({
  name: 'typeNotification',
  standalone: true,
  pure: true,
})
export class TypeNotificationPipe implements PipeTransform {
  transform(status: string | undefined): NotifTypeClass | null {
    switch (status) {
      case 'confirmed':
        return {
          class: 'mlk-bg-light-blue-gradient mlk-text-dark-blue',
          title: 'Inscription acceptée',
        };

      case 'refused':
        return {
          class: 'mlk-bg-light-grey mlk-text-dark-grey',
          title: 'Inscription refusée',
        };

      default:
        return null;
    }
  }
}
