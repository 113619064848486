import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'currentUserLocalTime',
  pure: true,
  standalone: true,
})
export class CurrentUserLocalTimePipe implements PipeTransform {
  transform(value: string, format = ''): string {
    moment.locale('fr'); // TODO : Adapt the local to user language
    const currentUserTimeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateOnUserTimeZone = moment(value).tz(currentUserTimeZone);
    return format.length > 0
      ? dateOnUserTimeZone.format(format)
      : dateOnUserTimeZone.format();
  }
}
