import { Component, Input, signal } from '@angular/core';

@Component({
  selector: 'mlk-tab',
  standalone: true,
  template: `
    <div [hidden]="!selected()" class="pane">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .pane {
        padding: 0 1px;
      }
    `,
  ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MlkTab {
  @Input() public label = '';

  public selected = signal(false);
}
