import {
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  input,
  output,
  signal,
} from '@angular/core';

@Directive({
  selector: '[mlkScrollPagination]',
  standalone: true,
})
export class ScrollPaginationDirective {
  private readonly element = inject<ElementRef<HTMLElement>>(ElementRef);

  private readonly destroyRef = inject(DestroyRef);

  private readonly _currentPage = signal(1);

  private readonly observer = this.intersectionObserver();

  readonly totalItem = input.required<number>();

  readonly isLoading = input.required<boolean>();

  readonly nextPage = output<number>();

  constructor() {
    this.observer.observe(this.element.nativeElement);

    this.destroyRef.onDestroy(() => {
      this.observer.disconnect();
      this.observer.unobserve(this.element.nativeElement);
    });
  }

  private intersectionObserver() {
    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };
    return new IntersectionObserver(entries => {
      if (
        entries[0].isIntersecting &&
        this.totalItem() > 0 &&
        this.isLoading() === false
      ) {
        const nextPage = this._currentPage() + 1;
        this._currentPage.set(nextPage);
        this.nextPage.emit(nextPage);
      }
    }, options);
  }
}
