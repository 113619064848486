import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profilPicture',
  standalone: true,
})
export class ProfilPicturePipe implements PipeTransform {
  transform(firstname: string, lastname: string): string {
    return (firstname.charAt(0) + lastname.charAt(0)).toUpperCase();
  }
}
