import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanToString',
  pure: true,
  standalone: true,
})
export class BooleanToStringPipe implements PipeTransform {
  transform(value: unknown | null | undefined): string {
    /**
     *  Verify falsy value before getBool because undefined
     *  or null will emit an error with JSON.parse()
     */
    if (!value || !this.getBool(value)) {
      return 'Non';
    }

    return 'Oui';
  }

  private getBool(val: unknown): boolean {
    return !!JSON.parse(String(val).toLowerCase());
  }
}
