<div class="stars-group">
  @if (label) {
    <div class="fw-bold">{{ label }}</div>
  }
  <div class="comment-stars">
    <ng-container *mlkLoopFor="max; reverse: true; let i = index">
      @if (i + 1; as starValue) {
        <input
          (change)="valueChange($any($event).target.value)"
          class="comment-stars-input"
          type="radio"
          [name]="name ?? id"
          [checked]="rate && starValue >= rate"
          [disabled]="disabled || readonly"
          [readonly]="readonly"
          [value]="starValue"
          [id]="id + '-' + starValue" />
        <label class="comment-stars-view" [for]="id + '-' + starValue">
          <mlk-icon svgName="full-star" />
        </label>
        @if (starValue - 0.5; as halfValue) {
          <input
            class="comment-stars-input"
            type="radio"
            (change)="valueChange($any($event).target.value)"
            [name]="name ?? id"
            [checked]="rate && halfValue >= rate"
            [disabled]="disabled || readonly"
            [readonly]="readonly"
            [value]="halfValue"
            [id]="id + '-' + halfValue" />
          <label
            class="comment-stars-view is-half"
            [for]="id + '-' + halfValue">
            <mlk-icon svgName="half-star" />
          </label>
        }
      }
    </ng-container>
  </div>
</div>
