import { Pipe, PipeTransform } from '@angular/core';
import { Embassy } from 'gen';

@Pipe({
  name: 'embassyAddress',
  pure: true,
  standalone: true,
})
export class EmbassyAddressPipe implements PipeTransform {
  transform(embassy: Embassy | null | undefined, separation = ''): string {
    if (!embassy) {
      return '';
    }
    const address = [embassy.city, embassy.postal_code, embassy.country];

    return address.reduce((acc, curr, i) => {
      return acc + (i === address.length - 1 ? `${separation} ` : ' ') + curr;
    });
  }
}
