@if (profil && profil['profile_picture']) {
  <img
    class="mlk-img-profile-image"
    [ngSrc]="profil['profile_picture']"
    width="60"
    height="60"
    alt="" />
} @else {
  <h3
    class="mlk-img-profile-image mlk-avatar-name mlk-bg-dark-blue mlk-text-white">
    {{ profil['first_name'] | profilPicture: profil['last_name'] }}
  </h3>
}
