import { NgModule } from '@angular/core';
import { MlkTabs } from './tabs.component';
import { MlkTab } from './tab.component';

const TABS = [MlkTabs, MlkTab];

@NgModule({
  imports: TABS,
  exports: TABS,
})
export class MlkTabsModule {}
