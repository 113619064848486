import { Pipe, PipeTransform } from '@angular/core';
import { EventAttendeeStatusEnum } from 'gen';

@Pipe({
  name: 'statusAttendee',
  standalone: true,
  pure: true,
})
export class StatusAttendeePipe implements PipeTransform {
  transform(status: EventAttendeeStatusEnum | undefined): string {
    switch (status) {
      case EventAttendeeStatusEnum.Pending:
        return 'En attente de validation';

      case EventAttendeeStatusEnum.Confirmed:
        return 'Inscription acceptée';

      case EventAttendeeStatusEnum.Refused:
        return 'Inscription refusée';

      case EventAttendeeStatusEnum.Cancelled:
        return 'Annulé';

      default:
        return '';
    }
  }
}
