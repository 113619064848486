@if (imageFullscreenView()) {
<div class="ng-image-fullscreen-view">
  <div class="lightbox-wrapper">
    <a class="close" (click)="closeLightbox()"></a>
    <div class="lightbox-div" #lightboxDiv>
      @if (showLoading()) {
      <div class="pre-loader">
        <div class="mnml-spinner"></div>
      </div>
      }
      <div
        class="lightbox-image-main"
        [ngStyle]="{'margin-left': marginLeft + 'px', 'grid-template-columns': 'repeat('+images.length+',1fr)', 'transition': effectStyle}">
        @for (img of images; track i; let i = $index ) {

        <div
          class="lightbox-image"
          [ngStyle]="{'width': popupWidth + 'px'}"
          [attr.id]="'ng-lightbox-image-' + i"
          (touchstart)="swipeLightboxImg($event, 'start')"
          (touchend)="swipeLightboxImg($event, 'end')"
          #lightboxImageDiv>
          <mlk-custom-img [imageUrl]="img" />
        </div>
        }
      </div>
      <div [ngClass]="{'show': title(), 'hide': !title()}" class="caption">
        {{ title() }}
      </div>
      @if (images.length > 1) {
      <a
        [ngClass]="{'disable': lightboxPrevDisable}"
        class="prev icons prev-icon"
        (click)="prevImageLightbox()"
        >&lsaquo;</a
      >
      <a
        [ngClass]="{'disable': lightboxNextDisable}"
        class="next icons next-icon"
        (click)="nextImageLightbox()"
        >&rsaquo;</a
      >
      }
    </div>
  </div>

  @if(paginationShow()){
  <div class="popup-pagination">
    {{currentImageIndex() + 1}} / {{images.length}}
  </div>
  }
</div>
}
