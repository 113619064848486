import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'mlk-custom-img',
  templateUrl: './slider-image.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderCustomImageComponent {
  imageUrl = input.required<string>();
}
